<template>
  <div class="dashboard">
    <SidebarMenu @categorySelected="updateSelectedCategory" />
    <div class="main-content">
      <HeaderBar />
      <DashboardContent :selectedCategory="selectedCategory" />
    </div>
  </div>
</template>

<script>
import SidebarMenu from '@/components/SidebarMenu.vue';
import HeaderBar from '@/components/HeaderBar.vue';
import DashboardContent from '@/components/DashboardContent.vue';

export default {
  name: 'DashboardView',
  components: {
    SidebarMenu,
    HeaderBar,
    DashboardContent
  },
  data() {
    return {
      selectedCategory: 'All'
    };
  },
  methods: {
    updateSelectedCategory(category) {
      this.selectedCategory = category;
    }
  }
};
</script>

<style scoped>
.dashboard {
  display: flex;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
</style>
