<template>
  <div class="container">
    <BannerPage />
    <div class="login-container">
      <h1>Bem vindo(a)</h1>
      <p class="message">Faça login para continuar</p>
      <form @submit.prevent="login" class="login-form">
        <input type="email" v-model="email" placeholder="E-mail" required>
        <input type="password" v-model="password" placeholder="Senha" required>
        <button type="submit" :disabled="loading">
          <span v-if="!loading">ENTRAR</span>
          <div v-else class="spinner"></div>
        </button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <a href="#" @click="forgotPassword" class="bold">Esqueci minha senha</a>
        <p>Não tem uma conta? <span class="bold">Crie uma agora.</span></p>
      </form>
    </div>
  </div>
</template>




<script>
import api from '@/services/api';
import router from '@/router'; // Importar o router
import BannerPage from '@/components/BannerPage'; // Importando o componente Banner

export default {
  name: 'UserLogin',
  components: {
    BannerPage
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      errorMessage: ''
    };
  },
  methods: {
    async login() {
      this.loading = true;
      this.errorMessage = '';
      try {
        const response = await api.login({ email: this.email, password: this.password });
        // console.log(response.data);
        // Salvar o token (opcional)
        localStorage.setItem('token', response.data.access_token);
        // Redirecionar para a página de dashboard
        router.push({ name: 'dashboard' });
      } catch (error) {
        console.error('Erro ao fazer login:', error);
        this.errorMessage = 'Usuário ou senha inválidos';
      } finally {
        this.loading = false;
      }
    },
    forgotPassword() {
      // Navegar para página de recuperação de senha
    },
    register() {
      // Navegar para página de registro
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  height: 100vh;
}

.banner {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; /* Cor de fundo do banner */
  position: relative;
}

.zipper {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0; /* Ajuste a posição para mais perto do topo */
}

.logo {
  width: 50%;
  position: absolute;
  bottom: 25%; /* Aumente o espaço entre o zíper e o logo */
}

.login-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  font-family: 'Arial', sans-serif;
}

h1 {
  margin-bottom: 10px;
  color: #000;
}

.message {
  margin-bottom: 20px;
  color: #666;
}

.login-form {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
}

input, button {
  padding: 10px;
  margin-bottom: 20px; /* Aumentado o espaço entre os elementos */
  border: 2px solid #000;
  border-radius: 15px; /* Aumentado o arredondamento */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Sombreado leve para inputs */
}

button {
  background-color: #000;
  color: white;
  cursor: pointer;
  padding: 15px 10px; /* Aumento do padding vertical para um botão mais alto */
  margin-bottom: 30px; /* Aumento do espaço após o botão */
  box-shadow: 0 6px 12px rgba(0,0,0,0.3); /* Sombreado mais pronunciado para o botão */
  transition: all 0.3s ease; /* Transição suavizada para efeitos hover */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

button[disabled] {
  cursor: not-allowed;
  background-color: #666;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

a, p {
  color: #000;
  text-decoration: none;
  text-align: center;
  display: block;
  margin-bottom: 10px;
}

.bold {
  font-weight: bold; /* Deixa o texto em negrito */
}

a:hover, p span:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 20px;
}
</style>





